import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import auth from "../api/auth";
import "./styles.css";
import b2Logo from "./Header/BambooboxFullLogoMark.svg";

import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

//import capillaryLogo from "../media/capillaryLogo.png";
import { Paper, Typography, makeStyles } from "@material-ui/core";
import { useState } from "react";
import TenantSearchBar from "./TenantListsPopup/TenantSearchBar";
import { MicrosoftLoginButton } from "./MicrosoftLoginButton";
import jwt_decode from "jwt-decode";
import { GoogleOAuthProvider,GoogleLogin } from '@react-oauth/google';
import { msalConfig } from '../authConfig';
import { CATEGORIES } from "./page2/constants";
import { PasswordLessLogin } from "./PasswordLessLogin";
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import { OtpTextFeild } from "./OtpTextFeild";
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
// "./loader.gif"
import loader from "../loader.gif"
import { fontWeight } from "@material-ui/system";

const msalInstance = new PublicClientApplication(msalConfig);
const SuitsLogin = (props) => {
 let tenantId = '';
 const [popupState, setPopupState] = useState(false);
 const [tenantsNames, setTenantsName] = useState([]);
//  const [selectTenantName, setSelectedTenantName] = useState('');
 const [userIP, setUserIP] = useState(null);
 const [googletoken, setGoogleToken] = useState('');
 const [authType, setAuthType] = useState('');
 const [showOtp,setShowotp]=useState(false);
 const [email,setEmail]=useState("");
 const [showError,setShowError]=useState(false);
 const [errorMessage,setErrorMessage]=useState("");
 const [warning,setWarning]=useState("");
 const [showWarning,setShowWarning]=useState(false);
 const [otpError,setOtpError]=useState("Incorrect Authentication code");
 const [showOtpError,setShowOtpError]=useState(false);
 const [validateOtp,setValidateOtp]=useState(true);
 const [isLoading,setIsLoading]=useState(false);
 
 // To fetch user's IP address
 useEffect(() => {
  fetch('https://api64.ipify.org?format=json')
    .then((response) => response.json())
    .then((data) => {
      setUserIP(data.ip);
      // console.log("User's IP address", userIP, data.ip);
    })
    .catch((error) => console.error('Error fetching IP:', error));
  }, []);

  const getLastLoginDate=async()=>{
    const timeZone=Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log(timeZone);
    fetch(`${window._env_.AUTH_URL}/users/get-last-login`, {
        method: "GET",
        credentials: "include",
      })
        .then((res) => res.json())
        .then((loginData) => { 
            console.log("response",loginData);
          if (loginData.success) {
            const time=new Date((typeof loginData.data === "string" ? new Date(loginData.data) : loginData.data).toLocaleString("en-US", {timeZone: timeZone})); 
            console.log("time",time.toString())
            props.setLastLoginTime(time.toString())
          }
          else{
            console.log(loginData.error);
          }
        });
  }

  const copyrightYear = new Date().getYear() - 100;

  /*const responseGoogle = (response) => {
 
        console.log(response);
        console.log(response.profileObj);
};*/

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      "& > *": {
        width: "498px",
        height: "357px",
        padding: "24px 36px 24px 36px",
        borderRadius:"24px"
      },
    },
    otpRoot: {
      display: "flex",
      flexWrap: "wrap",
      "& > *": {
        width: "498px",
        height: "320px",
        padding: "24px 36px 24px 36px",
        borderRadius:"24px"
      },
    },
  }));

  const history = useHistory();

  const authHandler = (err, data) => {
    // localStorage.clear()
    // sessionStorage.clear()
    // console.log("microsoft data",data,err);
    console.log("User's IP address using Microsoft login", userIP);
    if(!err && data && data.accessToken){
    let googleToken = data.accessToken;
    setGoogleToken(googleToken);
    // console.log('googledata: ' + JSON.stringify(googleToken))
    // console.log('googleTOKEN: ' + JSON.stringify(googleToken))
    auth
      .login({ token: googleToken, tenantId: tenantId, type: 'microsoft', ip: userIP })
      .then((data) => {
        setAuthType('microsoft');
        console.log('data: ' + JSON.stringify(data))
        if (data.success == true) {
          let decodedToken = jwt_decode(data.token);
          localStorage.setItem("b2bToken", data.token);
          window.parent.postMessage(data.token, 'https://*.vf.force.com');
          localStorage.setItem(
            "b2Permissions",
            JSON.stringify(data.permissions)
          );
          localStorage.setItem("b2bTenantId", data.tenantId);
          // .setTenantImage(data?.tenantImage?data?.tenantImage:"");
          localStorage.setItem("tenantImage",data?.tenantImage);
          localStorage.setItem("b2LoggedIn",true);
          sessionStorage.setItem("b2LoggedIn",true);
          localStorage.setItem("b2iat",decodedToken?.iat);
          localStorage.setItem("b2exp",decodedToken?.exp);
          getLastLoginDate()
          props.getNotifications()
          setTimeout(() => {
            props.tokenSetter(data.token);
          }, 10);

          // smartlook identity added here
          
          if(data.permissions && Array.isArray(data.permissions) && data.permissions?.length>0){
            history.push('/home');
          }else{
            history.push('/no-access');
          }
          const tenantId = localStorage.getItem("tenantId");
          window.smartlook('identify', decodedToken?.userUuid, {
            name: decodedToken?.name,
            email: decodedToken?.email,
            tenant: decodedToken?.tenantId,
          })
          window.pendo.initialize({
            visitor: {
              id: decodedToken?.email,
              name: decodedToken?.name,
              email: decodedToken?.email,
              tenantId: decodedToken?.tenantId,
            },

            account: {
              id: decodedToken?.email,
              name: decodedToken?.name,
              email: decodedToken?.email,
              tenantId: decodedToken?.tenantId,
            },
          });
          // window.FS.identify( decodedToken?.userUuid, {
          //   displayName:  decodedToken?.name,
          //   email: decodedToken?.email,
          //   tenant: decodedToken?.tenantId,
          //  });
        } else if (data['tenantNames']) {
          console.log('data in if ', data.tenantNames)
          //tenantsNames = data.tenantNames;
          setTenantsName(data.tenantNames)
          console.log('TN in if', tenantsNames)
          setPopupState(true);
        }
        else {
          alert("Tenant not registered");
        }
      })
      .catch((error) => {
        console.error("Error occured in authHandler", error);
      });
    }else{
      localStorage.clear();
      sessionStorage.clear();
      console.log("error triggered");
      history.push('/');
    }
    
  };

  const handleLogin = async (googleData) => {
    // localStorage.clear()
    // sessionStorage.clear()
    console.log("google Data ",googleData)
    console.log("User's IP address using Google login", userIP);
    // let googleToken = googleData;
    setGoogleToken(googleData);
    auth
      .login({ token: googleData.credential, tenantId: tenantId, type: 'google', ip: userIP })
      .then((data) => {
        setAuthType('google');
        // console.log('data: ' + JSON.stringify(data))
        if (data.success == true) {
          let decodedToken = jwt_decode(data.token);
          localStorage.setItem("b2bToken", data.token);
          localStorage.setItem("b2iat",decodedToken?.iat);
          localStorage.setItem("b2exp",decodedToken?.exp);
          window.parent.postMessage(data.token, 'https://*.vf.force.com');
          localStorage.setItem(
            "b2Permissions",
            JSON.stringify(data.permissions)
          );
          localStorage.setItem("tenantImage",data?.tenantImage);
          localStorage.setItem("b2bTenantId", data.tenantId);
          localStorage.setItem("b2LoggedIn",true);
          sessionStorage.setItem("b2LoggedIn",true);
          //props.setIsAuth(true);
          getLastLoginDate()
          props.getNotifications()
          console.log("window", window);
          setTimeout(() => {
            props.tokenSetter(data.token);
          }, 10);

          // smartlook identity added here
          
          if(data.permissions && Array.isArray(data.permissions) && data.permissions?.length>0){
            history.push('/home');
          }else{
            history.push('/no-access');
          }
          // window.FS.identify( decodedToken?.userUuid, {
          //   displayName:  decodedToken?.name,
          //   email: decodedToken?.email,
          //   tenant: decodedToken?.tenantId,
          // });
          window.smartlook('identify', decodedToken?.userUuid, {
            name: decodedToken?.name,
            email: decodedToken?.email,
            tenant: decodedToken?.tenantId,
          })
          window.pendo.initialize({
            visitor: {
              id: decodedToken?.email,
              name: decodedToken?.name,
              email: decodedToken?.email,
              tenantId: decodedToken?.tenantId,
            },

            account: {
              id: decodedToken?.email,
              name: decodedToken?.name,
              email: decodedToken?.email,
              tenantId: decodedToken?.tenantId,
            },
          });
        } else if (data['tenantNames']) {
          console.log("values in data", data);
          console.log('data in if ', data.tenantNames)
          //tenantsNames = data.tenantNames;
          setTenantsName(data.tenantNames)
          console.log('TN in if', tenantsNames)
          setPopupState(true);
        }
        else {
          alert("Tenant not registered");
        }
      })
      .catch((error) => {
        console.error("Error occured in handleLogin", error);
      });
  };

  const handlePasswordlessLogin = (email, code) => {
    console.log("User's IP address using passwordLess login", userIP);
    auth
      .login({ token: code, tenantId: tenantId, type: 'passwordLess', loginId: email, ip: userIP })
      .then((data) => {
        setAuthType('passwordLess');
        setIsLoading(false)
        // console.log('data: ' + JSON.stringify(data))
        if (data.success == true) {
          let decodedToken = jwt_decode(data.token);
          localStorage.setItem("b2bToken", data.token);
          localStorage.setItem("b2iat",decodedToken?.iat);
          localStorage.setItem("b2exp",decodedToken?.exp);
          localStorage.setItem(
            "b2Permissions",
            JSON.stringify(data.permissions)
          );
          localStorage.setItem("tenantImage",data?.tenantImage);
          localStorage.setItem("b2bTenantId", data.tenantId);
          localStorage.setItem("b2LoggedIn",true);
          sessionStorage.setItem("b2LoggedIn",true);
          //props.setIsAuth(true);
          getLastLoginDate()
          props.getNotifications()
          console.log("window", window);
          setTimeout(() => {
            props.tokenSetter(data.token);
          }, 10);

          
          if(data.permissions && Array.isArray(data.permissions) && data.permissions?.length>0){
            history.push('/home');
          }else{
            history.push('/no-access');
          }
          // history.push('/home');
          window.smartlook('identify', decodedToken?.userUuid, {
            name: decodedToken?.name,
            email: decodedToken?.email,
            tenant: decodedToken?.tenantId,
          })
          window.pendo.initialize({
            visitor: {
              id: decodedToken?.email,
              name: decodedToken?.name,
              email: decodedToken?.email,
              tenantId: decodedToken?.tenantId,
            },

            account: {
              id: decodedToken?.email,
              name: decodedToken?.name,
              email: decodedToken?.email,
              tenantId: decodedToken?.tenantId,
            },
          });
        } else if (data['tenantNames']) {
          console.log("values in data", data);
          console.log('data in if ', data.tenantNames)
          //tenantsNames = data.tenantNames;
          setGoogleToken(data?.token);
          setTenantsName(data.tenantNames)
          console.log('TN in if', tenantsNames)
          setPopupState(true);
          setShowotp(false);
        }else if(data.code===500){
          setShowError(true)
          setErrorMessage("unable to login, try after some time");
        }else if(data.code===402){
          setShowotp(false);
          setShowError(true)
          setErrorMessage("Due to code expiry, you were redirected back to login page");
        }else if(data.code===403){
          setOtpError("Invalid Authentication code");
          setShowOtpError(true);
          // setErrorMessage("last attempt to enter correct otp before getting locked out");
        }else if(data.code===404){
          setShowotp(false);
          setShowError(true)
          setErrorMessage("Account has been locked for 5 minutes due to multiple unsuccessful attempts");
        }else if(data.code===401){
          setOtpError("Invalid Authentication code");
          setShowOtpError(true);
        }
        else {
          alert("Tenant not registered");
        }
        setValidateOtp(true)
      })
      .catch((error) => {setValidateOtp(true) });
  }

  const callGoogleOauth = async (tenantName) => {
    // localStorage.clear()
    // sessionStorage.clear()
    console.log('token: ', JSON.stringify(googletoken));
    console.log('tenant ', tenantName);
    let token;
    if(authType==='google'){
      token=googletoken.credential;
    }else {
      token=googletoken;
    }
    auth.login({ token: token, tenantId: tenantName, type: authType, loginId:email })
      .then((data) => {
        let decodedToken = jwt_decode(data.token);
        // console.log("decodedToken", decodedToken);
        console.log('data: ' + JSON.stringify(data))
        if (data.success == true) {
          localStorage.setItem("b2bToken", data.token);
          localStorage.setItem("b2iat",decodedToken?.iat);
          localStorage.setItem("b2exp",decodedToken?.exp);
          localStorage.setItem("b2LoggedIn",true);
          sessionStorage.setItem("b2LoggedIn",true);
          localStorage.setItem("tenantImage",data?.tenantImage);
          getLastLoginDate()
          props.getNotifications()
          console.log("data.token", data.token)
          localStorage.setItem(
            "b2Permissions",
            JSON.stringify(data.permissions)
          );
          localStorage.setItem("b2bTenantId", data.tenantId);
          setTimeout(() => {
            props.tokenSetter(data.token);
          }, 10);
          if(data.permissions && Array.isArray(data.permissions) && data.permissions?.length>0){
            history.push('/home');
          }else{
            history.push('/no-access');
          }

          // smartlook identity added here
          window.smartlook('identify', decodedToken?.userUuid, {
            name: decodedToken?.name,
            email: decodedToken?.email,
            tenant: decodedToken?.tenantId,
          })
          window.pendo.initialize({
            visitor: {
              id: decodedToken?.email,
              name: decodedToken?.name,
              email: decodedToken?.email,
              tenantId: decodedToken?.tenantId,
            },

            account: {
              id: decodedToken?.email,
              name: decodedToken?.name,
              email: decodedToken?.email,
              tenantId: decodedToken?.tenantId,
            },
          });
          // window.FS.identify( decodedToken?.userUuid, {
          //   displayName:  decodedToken?.name,
          //   email: decodedToken?.email,
          //   tenant: decodedToken?.tenantId,
          //  });
        }
        else if(data.code===500){
          setShowError(true)
          setErrorMessage("unable to login, try after some time");
        }else if(data.code===402){
          setShowotp(false);
          setShowError(true)
          setErrorMessage("Due to code expiry, you were redirected back to login page");
        }else if(data.code===403){
          setOtpError("Invalid Authentication code");
          setShowOtpError(true);
          // setErrorMessage("last attempt to enter correct otp before getting locked out");
        }else if(data.code===404){
          setShowotp(false);
          setShowError(true)
          setErrorMessage("Account has been locked for 5 minutes due to multiple unsuccessful attempts");
        }else if(data.code===401){
          setOtpError("Invalid Authentication code");
          setShowOtpError(true);
          // setErrorMessage("invalid otp");
        }
         else {
          alert("Tenant not registered");
        }
      })
      .catch((error) => {
        console.error("Error occured in callGoogleOauth", error);
      });
  }

  const handlePasswordlessSignIn=()=>{
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({email:email}),
      };
    fetch(`${window._env_.AUTH_URL}/users/passwordlessSignInStart`, requestOptions)
        .then((res) => res.json())
        .then((response)=>{
          setIsLoading(false);
            if(response.success===true){
                if(response?.code===200){
                    // props.setMessage("otp sent successfully");
                    setShowotp(true);
                }else if(response?.code===201){
                    // props.setMessage("otp has already been sent");
                    setShowotp(true);
                }else if(response?.code===401){
                  setShowError(true);
                  let time=response?.time;
                  if(time){
                    time=Math.ceil(parseInt(time)/60);
                    setErrorMessage(`Account has been locked out for ${time} minutes  due to multiple failed attempts`);
                  }else{
                    setErrorMessage(`Account has been locked out for 5 minutes due to multiple failed attempts`);
                  }
                }
            }else if(response.success===false){
                if(response?.code===404){
                    setShowWarning(true);
                    setWarning("Seems like you have not signed up yet, Try again ")
                }else{
                    setErrorMessage("unable to login retry after sometime");
                }
            }
        }).catch((e)=>{
          console.log("error in passwordless login",e);
          setIsLoading(false);
        })
}

  useEffect(()=>{
  if(showError===true){
    setTimeout(()=>{
      setShowError(false);
    },10000)
  }
  },[showError])

  const getSelectedOption = (props) => {
    // console.log('token: ', googleToken)
    console.log('reached parent', props);
    if (props === false) {
      //  localStorage.setItem('b2btoken','');
      setPopupState(false);
    } else {
      callGoogleOauth(props);
    }

  };

  const classes = useStyles();
  return (
    <div
      className="container"
      style={{
        zIndex: 5200,
        height: "80vh",
        width: "80vw",
        background: "#f2f2f2",
        padding: "10vh 10vw",
        position: "fixed",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
      {showError && <div style={{
        display:'flex',
        padding:"8px 16px 8px 16px",
        width:"500px",
        backgroundColor:"rgba(245, 88, 67, 1)",
        borderRadius:"8px",
        gap:"8px",
        alignItems:"center"
      }}>
      <ErrorOutlineRoundedIcon style={{color:'white'}}  />
      <Typography variant="body2" style={{ fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "14px",
                    lineHeight: "20.02px",
                    
                    letterSpacing: "0.15px",
                    
                    color: "rgba(255, 255, 255, 1)",
                  }}>{errorMessage}</Typography>
      </div>}
        <div
          className={(showOtp || popupState)?classes.otpRoot:classes.root}
        >
          <Paper elevation={0} style={{
            display: "flex", flexDirection: "column", background: "#FFFFFF",
            
          }}>
            {/* <img
              src={capillaryLogo}
              alt="capillaryLogo"
              height="25"
              style={{ marginBottom: "26vh", height: "3vw" }}
            /> */}
            <div className={showOtp?"div-enable":'div-disable'} >
            <ArrowBackIosNewRoundedIcon  className={showOtp?"backIcon-enable":'backIcon-disable'} onClick={e=>setShowotp(false)}  />
            <img
              src={b2Logo}
              alt="bamboobox"
              className={showOtp?'logo-otp':'logo'}
            />
            </div>
            
            {isLoading?
            <div style={{display:'flex',alignItems:"center",justifyContent:"center",height:'inherit'}}> <img 
             src={loader}
              alt="loader"
              style={{
                height:"50px",
                width:"50px"
              }}
          /></div>:
            <>{popupState ? <TenantSearchBar data={tenantsNames} selectedOption={getSelectedOption} /> :
            <>
            {showOtp?
            <>
            <OtpTextFeild 
            handlePasswordlessSignIn={handlePasswordlessSignIn} 
            otpError={otpError} 
            showOtpError={showOtpError} 
            setShowOtpError={setShowOtpError} 
            setShowotp={setShowotp}
            setOtpError={setOtpError} 
            email={email} 
            setValidateOtp={setValidateOtp}
            validateOtp={validateOtp}
            setIsLoading={setIsLoading}
            handlePasswordlessLogin={handlePasswordlessLogin} 
            />
            </>
            :
            <div className="login-button">
                   <div style={{marginBottom:"16px"}}>
                   <GoogleOAuthProvider clientId={window._env_.GOOGLE_CLIENT_ID}>
                <GoogleLogin
                   
                  clientId={window._env_.GOOGLE_CLIENT_ID}
                  useOneTap
                  // buttonText="Sign In Using Google"
                  logo_alignment="center"
                  onSuccess={handleLogin}
                  onFailure={handleLogin}
                  shape="rectangular"
                  width={430}
                  height={42}
                  size="large"
                />
                </GoogleOAuthProvider>
                   </div>
                <div style={{marginBottom:"16px"}}>
                <MsalProvider instance={msalInstance}>
                <MicrosoftLoginButton authHandler={authHandler}/>
                </MsalProvider>
                </div>
                
                <div style={{marginBottom:"16px"}}>
                  <Typography className="hr-lines" variant="body1" style={{color:"rgb(0,0,0,0.38)"}}> or Sign in with email address</Typography>
                 
                </div>
               <PasswordLessLogin setIsLoading={setIsLoading} handlePasswordlessSignIn={handlePasswordlessSignIn} setShowWarning={setShowWarning} setWarning={setWarning} showWarning={showWarning} warning={warning} setShowotp={setShowotp} email={email} setEmail={setEmail} errorMessage={errorMessage} setErrorMessage={setErrorMessage} /> 
              </div>}
              </>
              }</>
            }
          </Paper>
        </div>
      </div>
      <div 
        // class="flex-foot" 
        style={{
          display: "flex",
          position: "absolute",
          bottom: "35px",
          left: "20px",
          justifyContent:"space-between",
          width:"95vw"
        }}
       >
       <div>
       <Typography variant="body1"  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    fontFamily:"Inter",
                    lineHeight: "24px",
                    color:"rgba(3, 3, 3, 0.6)",
                    }}>Copyright © 2021-{copyrightYear} BambooBox</Typography>
       </div>
       <div>
       <Typography variant="body1"  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    fontFamily:"Inter",
                    lineHeight: "24px",
                    color:"rgba(3, 3, 3, 0.6)",
                    }}>This site uses cookies and by using this site you agree to our <a
            href="https://bamboobox.ai/privacy-policy/"
            target="_blank"
            style={{ textDecoration: "underline",color:'rgba(33, 218, 140, 1)',fontWeight:"700px"  }} rel="noreferrer"
          >
            Privacy Policy
          </a> and  <a
            href="https://bamboobox.ai/terms-of-service/"
            target="_blank"
            style={{ textDecoration: "underline",color:'rgba(33, 218, 140, 1)', fontWeight:"700px" }} rel="noreferrer"
          >
            Terms of Service
          </a> </Typography>
       </div>
       
            
      </div>
    </div>
  );
};
export default SuitsLogin;
